<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 icon-vis2 gray-bg receptions-screen reception-email-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "utilities",
              "qr-code-templates",
              "add-qr-code-template"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <VeeForm
      ref="addTemplateForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      novalidate
    >
      <!-- name -->
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="name">
                {{ displayLabelName("utilities", "qr-code-templates", "name") }}
              </label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="
                  displayLabelName('utilities', 'qr-code-templates', 'name')
                "
              />
              <!-- <input
              type="text"
              :value="selectedTemplate.name"
              @change="event => handleInputChange(event, 'name')"
              id="name"
            /> -->
            </div>
          </div>
        </li>
      </ul>
      <!-- template_content -->
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item mail-flex"
          :class="{ 'has-error': errors.template_content }"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="template_content">
                {{
                  displayLabelName("utilities", "qr-code-templates", "content")
                }}
              </label>
            </div>
          </div>
        </li>
      </ul>
      <ul
        class="clebex-item-section mail-flex"
        :class="{ 'has-error': errors.template_content }"
        v-if="!isPreview"
      >
        <li class="clebex-item-section-item mail-flex">
          <span class="error-message" v-if="errors.template_content">
            {{ errors.template_content }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <Field
                name="template_content"
                as="textarea"
                type="text"
                rules="required"
                :placeholder="
                  displayLabelName('utilities', 'qr-code-templates', 'content')
                "
                v-model="templateContent"
              />
              <Field
                id="is_default"
                name="is_default"
                as="input"
                type="hidden"
                value="0"
              />
              <!-- <textarea
              type="text"
              :value="selectedTemplate.template_content"
              @change="event => handleInputChange(event, 'template_content')"
              id="body"
            /> -->
            </div>
          </div>
        </li>
      </ul>

      <ul class="clebex-item-section mail-flex" v-if="isPreview">
        <li class="clebex-item-section-item mail-flex">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input preview">
              <div v-html="templateContent"></div>
            </div>
          </div>
        </li>
      </ul>

      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: isPreview }"
              @click="setPreview"
            >
              {{
                displayLabelName(
                  "visitor-management",
                  "manage-reception-desks",
                  "preview"
                )
              }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions } from "vuex";

export default {
  name: "EditReceptionEmail",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  data() {
    return {
      isPreview: false,
      templateContent: null
    };
  },
  methods: {
    ...mapActions("qrCode", ["createTemplate", "getTemplates"]),
    setPreview() {
      this.isPreview = !this.isPreview;
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    submitForm(values) {
      if (values) {
        this.createTemplate(values)
          .then(() => {
            this.templateContent = null;
            return this.getTemplates();
          })
          .then(() => {
            this.$router.push({ name: this.backLinkName });
          });
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
